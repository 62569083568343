import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./pages/nl/nl.module').then((m) => m.NlModule)
    },
    // {
    //     path: 'digitalebrochure-leveranciers',
    //     pathMatch: 'full',
    //     loadChildren: () => import('./pages/brochure-contractors/brochure-contractors.module').then((m) => m.BrochureContractorsModule)
    // },
    // {
    //     path: 'digitalebrochure-beheerders',
    //     pathMatch: 'full',
    //     loadChildren: () => import('./pages/brochure-admins/brochure-admins.module').then((m) => m.BrochureAdminsModule)
    // },
    {
        path: 'en',
        pathMatch: 'full',
        loadChildren: () => import('./pages/en/en.module').then((m) => m.EnModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
