import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private readonly router: Router) {
    }

    public ngOnInit() {
        const path = window.location?.pathname ?? '';
        const browserLanguage = navigator.language.slice(0, 2);
        const onNLVersion = path === '/';
        const onENVersion = path === '/en';

        if (browserLanguage === 'nl') {
            if (onENVersion) {
                this.router.navigateByUrl('/', {
                    replaceUrl: true
                });
            }
        }

        if (browserLanguage === 'en') {
            if (onNLVersion) {
                this.router.navigateByUrl('/en', {
                    replaceUrl: true
                });
            }
        }
    }
}
